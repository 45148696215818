'use client'
import { Mic, Loader2, MicOff } from 'lucide-react';
import { useState, useEffect, useRef } from 'react';
import { City, TimezoneData } from '@store/citiesStore';
import toast from 'react-hot-toast';

declare global {
  interface Window {
    webkitSpeechRecognition: typeof SpeechRecognition;
  }
}

interface VoiceCommand {
  type: 'ADD' | 'REMOVE' | 'SET_HOME';
  locationKey?: string;  // For existing cities
  timezoneData?: TimezoneData;
}

interface VoiceInputProps {
  cities: Map<string, City>;
  onAddCity: (timezoneData: TimezoneData, setAsHome?: boolean) => void;
  onRemoveCity: (locationKey: string) => void;
  onSetHomeCity: (locationKey: string) => void;
}

export default function VoiceInput({ cities, onAddCity, onRemoveCity, onSetHomeCity }: VoiceInputProps) {
  const [isProcessing, setIsProcessing] = useState(false);
  const [isListening, setIsListening] = useState(false);
  const [isSupported, setIsSupported] = useState(true);
  const [isMicAllowed, setIsMicAllowed] = useState(true);
  const recognitionRef = useRef<SpeechRecognition | null>(null);

  const cleanupRecognition = () => {
    if (recognitionRef.current) {
      recognitionRef.current.stop();
      recognitionRef.current = null;
    }
    setIsProcessing(false);
    setIsListening(false);
  };

  useEffect(() => {
    return () => {
      cleanupRecognition();
    };
  }, []);

  useEffect(() => {
    // Check browser support
    setIsSupported('webkitSpeechRecognition' in window);

    // Check microphone permission
    async function checkMicrophonePermission() {
      try {
        // First check if the permissions API is supported
        if (navigator.permissions && navigator.permissions.query) {
          const permissionStatus = await navigator.permissions.query({ name: 'microphone' as PermissionName });

          // Update state based on current permission
          // Consider both 'granted' and 'prompt' as allowed states
          setIsMicAllowed(permissionStatus.state !== 'denied');

          // Listen for permission changes
          permissionStatus.addEventListener('change', () => {
            setIsMicAllowed(permissionStatus.state !== 'denied');
          });
        } else {
          // Fallback to getUserMedia to check permission
          const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
          stream.getTracks().forEach(track => track.stop()); // Clean up
          setIsMicAllowed(true);
        }
      } catch (error) {
        console.error('Error checking microphone permission:', error);
        setIsMicAllowed(false);
      }
    }

    checkMicrophonePermission();
  }, []);

  const handleVoiceInput = () => {
    if (!isSupported) {
      toast.error('Voice recognition is not supported in this browser.');
      return;
    }

    if (!isMicAllowed) {
      toast.error('Please allow microphone access to use voice commands');
      return;
    }

    // If already processing or listening, cancel the current recognition
    if (isProcessing || isListening) {
      cleanupRecognition();
      return;
    }

    console.log('Starting voice recognition...');
    const recognition = new window.webkitSpeechRecognition();
    recognitionRef.current = recognition;
    recognition.continuous = false;
    recognition.interimResults = false;
    recognition.lang = 'en-US';

    recognition.onstart = () => {
      console.log('Voice recognition started');
      setIsListening(true);
      toast.success('Listening...');
    };

    recognition.onend = () => {
      console.log('Voice recognition ended');
      setIsListening(false);
      recognition.stop();
      recognitionRef.current = null;
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      console.log('Voice transcription received:', transcript);
      processVoiceTranscription(transcript);
      recognition.stop();
    };

    recognition.onerror = (event) => {
      console.error('Voice recognition error:', event.error);
      setIsListening(false);
      recognition.stop();
      recognitionRef.current = null;

      // Don't show error message for aborted events
      if (event.error === 'aborted') return;

      switch (event.error) {
        case 'not-allowed':
          setIsMicAllowed(false);
          toast.error('Please allow microphone access to use voice commands');
          break;
        case 'no-speech':
          toast.error('No speech was detected. Please try again');
          break;
        default:
          toast.error(`Voice recognition error: ${event.error}`);
      }
    };

    recognition.start();
  };

  const processVoiceTranscription = async (transcription: string) => {
    try {
      console.log('Processing voice transcription:', transcription);
      console.log('Current cities:', Array.from(cities.values()));
      setIsProcessing(true);

      // Call voice-places API to get structured commands
      console.log('Calling voice-places API...');
      const voicePlacesRes = await fetch('/api/voice-places', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          transcription,
          cities: Array.from(cities.values()).map(city => ({
            locationKey: city.locationKey,
            name: city.name,
            country: city.country
          }))
        }),
      });

      if (!voicePlacesRes.ok) {
        const error = await voicePlacesRes.json();
        throw new Error(error.message || 'Failed to process voice input');
      }

      const commands: VoiceCommand[] = await voicePlacesRes.json();
      console.log('Received commands:', commands);

      // Sort commands by type to ensure correct execution order
      const removeCommands = commands.filter(cmd => cmd.type === 'REMOVE');
      const addCommands = commands.filter(cmd => cmd.type === 'ADD');
      const setHomeCommands = commands.filter(cmd => cmd.type === 'SET_HOME');

      // Process REMOVE commands first
      for (const command of removeCommands) {
        if (command.locationKey && cities.has(command.locationKey)) {
          const city = cities.get(command.locationKey);
          onRemoveCity(command.locationKey);
          toast.success(`Removed ${city?.name}`);
        }
      }

      // Process ADD commands next
      for (const command of addCommands) {
        if (command.timezoneData) {
          await onAddCity(command.timezoneData);
          toast.success(`Added ${command.timezoneData.city || command.timezoneData.country}`);
        }
      }

      // Process SET_HOME commands last
      for (const command of setHomeCommands) {
        if (command.locationKey && cities.has(command.locationKey)) {
          const city = cities.get(command.locationKey);
          onSetHomeCity(command.locationKey);
          toast.success(`Set ${city?.name} as home city`);
        }
      }

      console.log('Finished processing all commands');
    } catch (error) {
      toast.error(error instanceof Error ? error.message : 'Failed to process voice command');
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <button
      onClick={handleVoiceInput}
      disabled={isProcessing}
      title={!isSupported
        ? 'Voice recognition is not supported in this browser.'
        : !isMicAllowed
          ? 'Please allow microphone access to use voice commands'
          : 'Use voice commands'
      }
      className={`absolute right-2 top-1/2 -translate-y-1/2 p-1.5 rounded-full transition-colors duration-200
        ${isListening ? 'text-red-500' : 'text-white group-focus-within:text-blue-500'}
        ${isProcessing ? 'opacity-50 cursor-not-allowed' : 'hover:bg-white/10'}
        ${(!isSupported || !isMicAllowed) ? 'opacity-50' : ''}
      `}
    >
      {isProcessing ? (
        <Loader2 className="w-5 h-5 animate-spin" />
      ) : (!isSupported || !isMicAllowed) ? (
        <MicOff className="w-5 h-5" />
      ) : (
        <Mic className="w-5 h-5" />
      )}
    </button>
  );
} 
import { useState, useRef, useEffect } from 'react';
import { useClockStore, useClockSettingsStore } from '@store/timeStore';
import { useCitiesStore } from '@store/citiesStore';
import { DEGREES_PER_HOUR } from '@lib/meetingUtils';

const TAP_THRESHOLD_MS = 300; // Maximum duration for a touch to be considered a tap
const MOVEMENT_THRESHOLD_PX = 3; // Maximum pixel distance for a touch to be considered a tap rather than a drag
const ANIMATION_DURATION_MS = 200;

interface UseDragTimeOptions {
  onDragStart?: () => void;
  onDragEnd?: () => void;
}

export function useDragTime({ onDragStart, onDragEnd }: UseDragTimeOptions = {}) {
  const { snapToNearest15, setGlobalIsDragging } = useClockSettingsStore();
  const { setBaseTime, getAdjustedTime, setIsAdjusted } = useClockStore();

  const [isDragging, setIsDragging] = useState(false);
  const clockCenter = useRef<{ x: number; y: number }>({ x: 0, y: 0 });
  const hasMovedRef = useRef(false);
  const startAngleRef = useRef(0);
  const initialTimeRef = useRef<Date | null>(null);
  const dragStartTimeRef = useRef<number>(0);
  const rafRef = useRef<number>();

  const getClockCenter = () => {
    const clockContainer = document.getElementById('clock-container');
    if (clockContainer) {
      const rect = clockContainer.getBoundingClientRect();
      clockCenter.current = {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
      };
    }
  };

  const calculateAngle = (clientX: number, clientY: number): number => {
    const dx = clientX - clockCenter.current.x;
    const dy = clientY - clockCenter.current.y;
    let radians = Math.atan2(dy, dx) - Math.PI / 2;
    if (radians < 0) radians += 2 * Math.PI;
    const degrees = radians * (180 / Math.PI);
    return degrees;
  };

  const calculateHourFromAngle = (angle: number, offsetHours: number = 0): number => {
    const adjustedAngle = (angle + (offsetHours * DEGREES_PER_HOUR) + 360) % 360;
    const decimalHours = (adjustedAngle / 360) * 24;
    const hour = Math.floor(decimalHours);
    return (hour + 24) % 24;
  };

  const animateTimeChange = (startTime: Date, targetTime: Date) => {
    const startMs = startTime.getTime();
    const endMs = targetTime.getTime();
    const diffMs = endMs - startMs;
    const startTimestamp = performance.now();

    const animate = (currentTimestamp: number) => {
      const elapsed = currentTimestamp - startTimestamp;
      const progress = Math.min(elapsed / ANIMATION_DURATION_MS, 1);

      const eased = progress < .5 ?
        4 * progress * progress * progress :
        1 - Math.pow(-2 * progress + 2, 3) / 2;

      const currentMs = startMs + (diffMs * eased);
      setBaseTime(new Date(currentMs));

      if (progress < 1) {
        requestAnimationFrame(animate);
      }
    };

    requestAnimationFrame(animate);
  };

  const setTimeToHour = (hour: number) => {
    const currentTime = getAdjustedTime();
    const minutes = currentTime.getMinutes();
    const newTime = new Date(currentTime);
    newTime.setHours(hour, minutes, 0, 0);
    setIsAdjusted(true);
    animateTimeChange(currentTime, newTime);
  };

  const handleDragStart = (clientX: number, clientY: number) => {
    setIsAdjusted(true);
    setGlobalIsDragging(true);
    getClockCenter();
    setIsDragging(true);
    hasMovedRef.current = false;
    startAngleRef.current = calculateAngle(clientX, clientY);
    initialTimeRef.current = getAdjustedTime();
    dragStartTimeRef.current = Date.now();
    onDragStart?.();
  };

  const handleDragMove = (clientX: number, clientY: number) => {
    if (!isDragging || !initialTimeRef.current) return;

    // Calculate distance moved from the start position
    const dx = clientX - clockCenter.current.x;
    const dy = clientY - clockCenter.current.y;
    const distance = Math.sqrt(dx * dx + dy * dy);

    // Mark as moved if distance exceeds threshold
    if (distance > MOVEMENT_THRESHOLD_PX) {
      hasMovedRef.current = true;
    }

    // Only update time if we've detected significant movement
    if (hasMovedRef.current) {
      const currentAngle = calculateAngle(clientX, clientY);
      const angleDelta = currentAngle - startAngleRef.current;
      const hoursDelta = (angleDelta / 360) * 24;
      const millisecondsDelta = hoursDelta * 60 * 60 * 1000;
      const newTime = new Date(initialTimeRef.current.getTime() + millisecondsDelta);

      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }

      rafRef.current = requestAnimationFrame(() => {
        setBaseTime(newTime);
      });
    }
  };

  const handleDragEnd = (clientX: number, clientY: number) => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current);
    }

    const dragDuration = Date.now() - dragStartTimeRef.current;

    // Handle tap interaction (short duration, minimal movement)
    if (dragDuration < TAP_THRESHOLD_MS && !hasMovedRef.current) {
      const angle = calculateAngle(clientX, clientY);

      // Get the home city's timezone
      const { cities, homeCity } = useCitiesStore.getState();
      const homeTimezone = homeCity ? cities.get(homeCity)?.timezone : null;

      if (homeTimezone) {
        // Calculate the offset between local time and home timezone
        const now = new Date();
        const localTime = new Date();
        const homeTime = new Date(now.toLocaleString('en-US', { timeZone: homeTimezone }));

        // Calculate hour difference between local and home timezone
        const localHour = localTime.getHours();
        const homeHour = homeTime.getHours();
        let hourDiff = homeHour - localHour;

        // Normalize hour difference to be within -12 to 12 range
        if (hourDiff > 12) hourDiff -= 24;
        if (hourDiff < -12) hourDiff += 24;

        const hour = calculateHourFromAngle(angle, -hourDiff);
        setTimeToHour(hour);
      } else {
        // Fallback to local time if no home timezone is set
        const hour = calculateHourFromAngle(angle);
        setTimeToHour(hour);
      }
    } else if (hasMovedRef.current) {
      // If dragged, snap to nearest 15-minute interval
      snapToNearest15();
    }

    // Reset drag state
    setGlobalIsDragging(false);
    setIsDragging(false);
    hasMovedRef.current = false;
    initialTimeRef.current = null;
    onDragEnd?.();
  };

  const eventHandlers = {
    onMouseDown: (e: React.MouseEvent) => {
      e.preventDefault();
      handleDragStart(e.clientX, e.clientY);
    },
    onMouseMove: (e: MouseEvent) => {
      handleDragMove(e.clientX, e.clientY);
    },
    onMouseUp: (e: MouseEvent) => {
      handleDragEnd(e.clientX, e.clientY);
    },
    onTouchStart: (e: React.TouchEvent) => {
      const touch = e.touches[0];
      handleDragStart(touch.clientX, touch.clientY);
    },
    onTouchMove: (e: TouchEvent) => {
      const touch = e.touches[0];
      handleDragMove(touch.clientX, touch.clientY);
    },
    onTouchEnd: (e: TouchEvent) => {
      const touch = e.changedTouches[0];
      handleDragEnd(touch.clientX, touch.clientY);
    }
  };

  useEffect(() => {
    // Add drag event listeners when dragging starts
    if (isDragging) {
      window.addEventListener('mousemove', eventHandlers.onMouseMove);
      window.addEventListener('mouseup', eventHandlers.onMouseUp);
      window.addEventListener('touchmove', eventHandlers.onTouchMove, { passive: true });
      window.addEventListener('touchend', eventHandlers.onTouchEnd);
    }

    // Cleanup function to remove listeners and cancel any pending animation
    return () => {
      window.removeEventListener('mousemove', eventHandlers.onMouseMove);
      window.removeEventListener('mouseup', eventHandlers.onMouseUp);
      window.removeEventListener('touchmove', eventHandlers.onTouchMove);
      window.removeEventListener('touchend', eventHandlers.onTouchEnd);
      if (rafRef.current) {
        cancelAnimationFrame(rafRef.current);
      }
    };
  }, [isDragging, eventHandlers.onMouseMove, eventHandlers.onMouseUp, eventHandlers.onTouchMove, eventHandlers.onTouchEnd]);

  return {
    isDragging,
    eventHandlers: {
      onMouseDown: eventHandlers.onMouseDown,
      onTouchStart: eventHandlers.onTouchStart
    }
  };
} 
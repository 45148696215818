import { create } from 'zustand';
import { persist } from 'zustand/middleware';

/**
 * Cities Store
 * 
 * This store manages the list of cities/locations for timezone tracking.
 * 
 * Important Notes:
 * - Locations can be either cities or countries
 * - For cities: locationKey format is "cityname-countrycode" (e.g., "london-gb")
 * - For countries: locationKey format is just "countrycode" (e.g., "kw" for Kuwait)
 * - When adding locations:
 *   - If city is available, use city + country (e.g., "Kuwait City, KW")
 *   - If only country is available, use country as both name and location (e.g., "Kuwait")
 *   - Both formats are valid and should be supported
 */

export interface City {
  name: string;          // Can be either city name or country name
  timezone: string;      // IANA timezone identifier
  emoji: string;         // Country flag emoji
  abbreviation: string;  // Abbreviated name (can be same as name for countries)
  country: string;       // Country code
  locationKey: string;   // "cityname-countrycode" or "countrycode"
}

export interface TimezoneData {
  timezone: string;
  city?: string;
  emoji: string;
  abbreviation: string;
  country: string;
}

function getOffsetFromHome(timezone: string, homeTimezone: string | undefined): number {
  if (!homeTimezone) return 0;
  const now = new Date();
  const homeOffset = -now.getTimezoneOffset() + new Date().getTimezoneOffset() +
    Number(new Date().toLocaleString("en-US", { timeZone: homeTimezone, hour: 'numeric', hour12: false })) * 60;
  const cityOffset = -now.getTimezoneOffset() + new Date().getTimezoneOffset() +
    Number(new Date().toLocaleString("en-US", { timeZone: timezone, hour: 'numeric', hour12: false })) * 60;
  return cityOffset - homeOffset;
}

function reorderCities(cities: Map<string, City>, homeCity: string | null): Map<string, City> {
  const homeTimezone = homeCity ? cities.get(homeCity)?.timezone : undefined;
  const citiesList = Array.from(cities.values()).sort((a, b) => {
    if (a.locationKey === homeCity) return -1;
    if (b.locationKey === homeCity) return 1;
    return getOffsetFromHome(a.timezone, homeTimezone) - getOffsetFromHome(b.timezone, homeTimezone);
  });

  return new Map(citiesList.map(city => [city.locationKey, city]));
}

const initialCities = new Map([
  ['miami-us', {
    name: 'Miami',
    timezone: 'America/New_York',
    emoji: '🇺🇸',
    abbreviation: 'Miami',
    country: 'US',
    locationKey: 'miami-us'
  }],
  ['melbourne-au', {
    name: 'Melbourne',
    timezone: 'Australia/Melbourne',
    emoji: '🇦🇺',
    abbreviation: 'Melbourne',
    country: 'AU',
    locationKey: 'melbourne-au'
  }]
]);

function addCityToState(state: {
  cities: Map<string, City>;
  homeCity: string | null;
  isDefaultHomeCity: boolean;
  hasAttemptedGeolocation: boolean;
  isHydrated: boolean;
  addCity: (city: City) => void;
  addCityByAddress: (address: string, setAsHome?: boolean) => Promise<void>;
  addCityWithTimezoneData: (timezoneData: TimezoneData, setAsHome?: boolean) => void;
  removeCity: (locationKey: string) => void;
  setHomeCity: (locationKey: string) => void;
  setHasAttemptedGeolocation: (value: boolean) => void;
}, timezoneData: TimezoneData, setAsHome: boolean) {
  // Validate required fields (note: city is optional)
  if (!timezoneData.country || !timezoneData.timezone || !timezoneData.emoji) {
    console.error('Invalid timezone data:', timezoneData);
    return state;
  }

  // Generate locationKey based on whether we have a city or just a country
  const locationKey = timezoneData.city
    ? `${timezoneData.city.toLowerCase()}-${timezoneData.country.toLowerCase()}`
    : timezoneData.country.toLowerCase();

  // Get proper name for the location
  let locationName = timezoneData.city;
  if (!locationName) {
    // For country-only cases, try to get a proper name from the abbreviation
    // If abbreviation is not provided or is the same as the country code,
    // use the country code (as a fallback)
    locationName = timezoneData.abbreviation &&
      timezoneData.abbreviation.toUpperCase() !== timezoneData.country
      ? timezoneData.abbreviation
      : timezoneData.country;
  }

  // Create the city object, using appropriate fallbacks for country-only locations
  const newCity: City = {
    name: locationName,
    timezone: timezoneData.timezone,
    emoji: timezoneData.emoji,
    abbreviation: timezoneData.abbreviation || locationName,
    country: timezoneData.country,
    locationKey,
  };

  // Validate the new city object
  if (!newCity.locationKey || !newCity.emoji) {
    console.error('Invalid city object:', newCity);
    return state;
  }

  const newCities = new Map(state.cities);
  newCities.set(locationKey, newCity);

  const shouldSetNewHome = setAsHome || !state.homeCity;
  const newHomeCity = shouldSetNewHome ? locationKey : state.homeCity;

  return {
    ...state,
    cities: reorderCities(newCities, newHomeCity),
    homeCity: newHomeCity,
    isDefaultHomeCity: shouldSetNewHome ? false : state.isDefaultHomeCity,
  };
}

export const useCitiesStore = create<{
  cities: Map<string, City>;
  homeCity: string | null;
  isDefaultHomeCity: boolean;
  hasAttemptedGeolocation: boolean;
  isHydrated: boolean;
  addCity: (city: City) => void;
  addCityByAddress: (address: string, setAsHome?: boolean) => Promise<void>;
  addCityWithTimezoneData: (timezoneData: TimezoneData, setAsHome?: boolean) => void;
  removeCity: (locationKey: string) => void;
  setHomeCity: (locationKey: string) => void;
  setHasAttemptedGeolocation: (value: boolean) => void;
}>()(
  persist(
    (set) => ({
      cities: new Map(),
      homeCity: null,
      isDefaultHomeCity: true,
      hasAttemptedGeolocation: false,
      isHydrated: false,

      addCityByAddress: async (address: string, setAsHome = false) => {
        try {
          const timezoneRes = await fetch('/api/timezone', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ address }),
          });
          const timezoneData = await timezoneRes.json();

          if (!timezoneData.timezone || !timezoneData.country || !timezoneData.emoji) return;

          set((state) => addCityToState(state, timezoneData, setAsHome));
        } catch (error) {
          console.error('Error:', error);
        }
      },

      addCity: (city: City) => set((state) => ({
        ...state,
        cities: reorderCities(new Map(state.cities).set(city.locationKey, city), state.homeCity || city.locationKey),
        homeCity: state.homeCity || city.locationKey
      })),

      addCityWithTimezoneData: (timezoneData: TimezoneData, setAsHome = false) => {
        if (!timezoneData.timezone || !timezoneData.country || !timezoneData.emoji) return;
        set((state) => addCityToState(state, timezoneData, setAsHome));
      },

      removeCity: (locationKey: string) => set((state) => {
        const newCities = new Map(state.cities);
        newCities.delete(locationKey);
        const citiesArray = Array.from(newCities.keys());
        const newHomeCity = state.homeCity === locationKey ? citiesArray[0] || null : state.homeCity;

        return {
          ...state,
          cities: reorderCities(newCities, newHomeCity),
          homeCity: newHomeCity || (citiesArray.length > 0 ? citiesArray[0] : null),
          isDefaultHomeCity: false,
        };
      }),

      setHomeCity: (locationKey: string) => set((state) => ({
        ...state,
        cities: reorderCities(state.cities, locationKey),
        homeCity: locationKey,
        isDefaultHomeCity: false,
      })),

      setHasAttemptedGeolocation: (value: boolean) => set((state) => ({
        ...state,
        hasAttemptedGeolocation: value
      })),
    }),
    {
      name: 'cities-storage',
      onRehydrateStorage: () => (state) => {
        if (state) {
          if (state.cities.size === 0 && !state.hasAttemptedGeolocation) {
            state.cities = initialCities;
            state.homeCity = 'miami-us';
          }
          state.isHydrated = true;
        }
      },
      storage: {
        getItem: (name) => {
          const str = localStorage.getItem(name);
          if (!str) return null;
          const { state } = JSON.parse(str);

          // Validate and fix cities during rehydration
          const validatedCities = new Map();
          if (state.cities) {
            Object.entries(state.cities).forEach(([_key, data]) => {
              // Type guard to validate city data
              const cityData = data as Partial<City>;

              // Skip invalid cities
              if (!cityData.country || !cityData.timezone || !cityData.emoji ||
                !cityData.name || !cityData.locationKey) {
                return;
              }

              // If we get here, we know we have a valid City
              validatedCities.set(cityData.locationKey, cityData as City);
            });
          }

          // If this is first time initialization
          if (validatedCities.size === 0 && !state.hasAttemptedGeolocation) {
            return {
              state: {
                cities: initialCities,
                homeCity: 'miami-us',
                isDefaultHomeCity: true,
                hasAttemptedGeolocation: false
              }
            };
          }

          // Ensure homeCity is valid or null
          const homeCity = state.homeCity && validatedCities.has(state.homeCity)
            ? state.homeCity
            : validatedCities.size > 0 ? Array.from(validatedCities.keys())[0] : null;

          return {
            state: {
              cities: validatedCities,
              homeCity,
              isDefaultHomeCity: state.isDefaultHomeCity,
              hasAttemptedGeolocation: state.hasAttemptedGeolocation
            }
          };
        },
        setItem: (name, value) => {
          const { state } = value;
          console.log('Storage - Saving state:', {
            citiesCount: state.cities.size,
            cities: Array.from(state.cities.entries()),
            homeCity: state.homeCity
          });
          localStorage.setItem(name, JSON.stringify({
            state: {
              cities: Object.fromEntries(state.cities),
              homeCity: state.homeCity,
              isDefaultHomeCity: state.isDefaultHomeCity,
              hasAttemptedGeolocation: state.hasAttemptedGeolocation
            }
          }));
        },
        removeItem: (name) => localStorage.removeItem(name),
      },
    }
  )
);

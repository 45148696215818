'use client'
import React, { useEffect } from 'react';
import ResetOffsetButton from './components/MainClockControls';
import { useCitiesStore } from '@/store/citiesStore';
import { Analytics } from '@vercel/analytics/react';
import TimeFormatToggle from '@components/MainClockControls/TimeFormatToggle';
import LogRocket from 'logrocket';
import ConcentricCityClock from '@components/Clock/ConcentricCityClock';
import { CreateMeetingCard } from '@components/Meeting/create-meeting-card';
import CitySearch from '@components/CityManagement/CitySearch';
import ClockSwitcher from '@components/ClockSwitcher';
import { useClockSettingsStore } from '@store/timeStore';
import Clock from '@components/Clock';

// Initialize LogRocket
if (typeof window !== 'undefined') {
  LogRocket.init('98k6pd/timeywimey');
  console.log('LogRocket initialized');
} else {
  console.log('LogRocket not initialized');
}

export default function Home() {
  const {
    addCityByAddress,
    homeCity,
    isDefaultHomeCity,
    hasAttemptedGeolocation,
    setHasAttemptedGeolocation,
    isHydrated
  } = useCitiesStore();
  const { clockMode } = useClockSettingsStore();

  useEffect(() => {
    // Only proceed if the store is hydrated
    if (!isHydrated) return;

    console.log('Current hydrated state:', {
      hasAttemptedGeolocation,
      isDefaultHomeCity,
      homeCity,
      isHydrated
    });

    // Only attempt geolocation if we haven't tried before
    if (!hasAttemptedGeolocation && (isDefaultHomeCity || !homeCity)) {
      console.log('Attempting geolocation...');
      fetch('/api/geolocation')
        .then(response => response.json())
        .then(data => {
          console.log('Geolocation response:', data);
          if (data.country) {
            const address = [
              data.city,
              data.region,
              data.country
            ].filter(Boolean).join(', ');
            console.log('Adding location with address:', address);
            addCityByAddress(address, true);
          } else {
            console.log('Insufficient geolocation data - missing country:', data);
          }
          setHasAttemptedGeolocation(true);
        })
        .catch(error => {
          console.error('Error fetching geolocation:', error);
          setHasAttemptedGeolocation(true);
        });
    }
  }, [addCityByAddress, homeCity, isDefaultHomeCity, hasAttemptedGeolocation, setHasAttemptedGeolocation, isHydrated]);

  return (
    <>
      <main className="min-h-screen flex flex-col landscape:flex-row landscape:h-screen landscape:overflow-hidden">
        {/* Header - Full width in landscape */}
        <div className="w-full mt-2 landscape:fixed landscape:top-0 landscape:z-10 landscape:bg-white/80 landscape:backdrop-blur-sm">
          <div className="w-full h-14 flex justify-between items-center px-4 text-blue-500">
            <ClockSwitcher />
            <ResetOffsetButton />
            <TimeFormatToggle />
          </div>
        </div>

        {/* Left column in landscape / Top section in portrait */}
        <div className="w-full landscape:w-1/2 landscape:h-screen landscape:pt-12">
          {clockMode === "clock" ? <Clock /> : <ConcentricCityClock />}
          <div className="px-4 pt-4 flex justify-center">
            <CitySearch />
          </div>
          <div className="landscape:hidden px-4 pb-4">
            <div className="mt-4">
              <CreateMeetingCard />
            </div>
          </div>
        </div>

        {/* Right column in landscape / Bottom section in portrait */}
        <div className="w-full landscape:w-1/2 landscape:h-screen landscape:overflow-y-auto landscape:pt-16">
          <div className="hidden landscape:block w-full px-4 pb-4">
            <div className="mt-4">
              <CreateMeetingCard />
            </div>
          </div>
        </div>
      </main>
      <Analytics />
    </>
  );
}

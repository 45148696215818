import { google } from 'calendar-link';
import { outlook } from 'calendar-link';
import { office365 } from 'calendar-link';
import { yahoo } from 'calendar-link';
import { createICSFile } from './ics';

export type CalendarProvider = 'google' | 'outlook' | 'office365' | 'yahoo' | 'ics' | 'apple';

export function createCalendarEvent(
  provider: CalendarProvider,
  timeDetails: {
    title: string;
    description: string;
    startTime: Date;
    endTime: Date;
  }
) {
  const event = {
    title: timeDetails.title,
    description: timeDetails.description,
    start: timeDetails.startTime,
    end: timeDetails.endTime,
  };

  let url: string;

  switch (provider) {
    case 'google':
      url = google(event);
      window.open(url, '_blank');
      break;
    case 'outlook':
      url = outlook(event);
      window.open(url, '_blank');
      break;
    case 'office365':
      url = office365(event);
      window.open(url, '_blank');
      break;
    case 'yahoo':
      url = yahoo(event);
      window.open(url, '_blank');
      break;
    case 'ics':
      createICSFile(event);
      break;
    case 'apple':
      createICSFile(event);
      break;
    default:
      console.error('Unsupported calendar provider');
  }
} 
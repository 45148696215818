"use client";

import { TW_CLOCK_SIZE } from "@components/Clock";
import { CityClock } from "@components/Clock/CityClock";
import { useDragTime } from "@hooks/useDragTime";
import {
  DEGREES_PER_HOUR,
  determineOuterSegmentColors,
  getColorForCategory,
  getTimeCategory,
  getTimeForCity,
  getTimezoneOffset,
  timeSegments,
} from "@lib/meetingUtils";
import { useCitiesStore, type City } from "@store/citiesStore";
import { useClockStore } from "@store/timeStore";
import React, { useMemo } from "react";
import { NumberLabels } from "./ClockBackground";

interface CityRingProps {
  city: City;
  index: number;
  homeTimezone: string;
  getTimeForCityFunc: (timezone: string) => Date;
}

function roundCoordinate(value: number): number {
  return Math.round(value * 1000) / 1000;
}

const CityRing: React.FC<CityRingProps> = ({
  city,
  index,
  homeTimezone,
  getTimeForCityFunc,
}) => {
  const cityTime = getTimeForCityFunc(city.timezone);
  const hours = cityTime.getHours();
  const minutes = cityTime.getMinutes();
  const angle = ((hours + minutes / 60) / 24) * 360;
  const baseTime = useClockStore((state) => state.getAdjustedTime)();

  // Calculate ring sizes using percentages
  const ringThickness = 6; // percentage

  // Calculate the outer and inner values for this ring
  // Start from the outermost ring (index 0) and work inwards
  const outerInset = index * ringThickness;

  // Calculate city radius - should be in the middle of the ring
  const cityRadius = 48 - index * ringThickness;

  // Calculate timezone offset for rotation of the ring only
  const offsetHours = getTimezoneOffset(city.timezone, homeTimezone);
  const rotationDegrees = offsetHours * DEGREES_PER_HOUR;

  // Generate conic gradient for this ring
  const generateRingGradient = () => {
    // Group segments by color
    const colorRanges: {
      color: string;
      startMinute: number;
      endMinute: number;
    }[] = [];

    // Convert offset hours to minutes and handle fractional offsets
    const offsetMinutes = Math.round(offsetHours * 60);

    // Start with first 15-minute segment
    const adjustedStartMinute = (0 + offsetMinutes + 24 * 60) % (24 * 60);
    let currentRange = {
      color: getColorForCategory(
        getTimeCategory(Math.floor(adjustedStartMinute / 60))
      ),
      startMinute: 0,
      endMinute: 15,
    };

    // Merge continuous ranges of the same color, checking every 15 minutes
    for (let minute = 15; minute < 24 * 60; minute += 15) {
      const adjustedMinute = (minute + offsetMinutes + 24 * 60) % (24 * 60);
      const color = getColorForCategory(
        getTimeCategory(Math.floor(adjustedMinute / 60))
      );

      if (color === currentRange.color) {
        currentRange.endMinute = minute + 15;
      } else {
        colorRanges.push({ ...currentRange });
        currentRange = { color, startMinute: minute, endMinute: minute + 15 };
      }
    }
    colorRanges.push(currentRange);

    // Generate gradient string from merged ranges
    const gradientStops = colorRanges
      .map(({ color, startMinute, endMinute }) => {
        const startDegree = (startMinute / (24 * 60)) * 360;
        const endDegree = (endMinute / (24 * 60)) * 360;
        return `${color} ${startDegree}deg, ${color} ${endDegree}deg`;
      })
      .join(", ");

    // Rotate from 180 degrees (top of clock)
    return `conic-gradient(from 180deg, ${gradientStops})`;
  };

  const maskSize = `${65 - index * 2}%`;
  return (
    <div className="absolute inset-0">
      <div
        className="absolute rounded-full opacity-65 saturate-[75%]"
        style={{
          inset: `${outerInset}%`,
          background: generateRingGradient(),
          mixBlendMode: "multiply",
          maskImage: `radial-gradient(transparent ${maskSize}, black ${maskSize})`,
          WebkitMaskImage: `radial-gradient(transparent ${maskSize}, black ${maskSize})`,
          zIndex: 10,
        }}
      />
      <div className="absolute inset-0" style={{ zIndex: 20 }}>
        <CityClock
          cities={[city]}
          angle={angle - rotationDegrees}
          baseTime={baseTime}
          homeCity={homeTimezone}
          radius={cityRadius}
          hideLabel={true}
        />
      </div>
    </div>
  );
};

export const ConcentricCityClock: React.FC = () => {
  const { cities, homeCity } = useCitiesStore();
  const { getAdjustedTime } = useClockStore();
  const homeTimezone = (homeCity && cities.get(homeCity)?.timezone) || "UTC";
  const { isDragging, eventHandlers } = useDragTime();

  const getTimeForCityFunc = (timezone: string) => {
    return getTimeForCity(timezone, () => getAdjustedTime());
  };

  // Memoize the outer ring calculations and gradient
  const { outerGradient } = useMemo(() => {
    const citiesRecord = Object.fromEntries(cities) as Record<string, City>;
    const segmentColors = determineOuterSegmentColors(
      citiesRecord,
      timeSegments,
      homeTimezone
    );

    // Generate outer ring gradient
    let gradient = "conic-gradient(from 180deg, ";
    const segments = timeSegments.map((segment) => {
      const key = `${segment.startMinute}-${segment.endMinute}`;
      const color = segmentColors[key] || "#E0E0E0";
      const startDegree = (segment.startMinute / (24 * 60)) * 360;
      const endDegree = (segment.endMinute / (24 * 60)) * 360;
      return { color, startDegree, endDegree };
    });

    // Merge adjacent segments of the same color
    const mergedSegments = segments.reduce((acc, segment) => {
      const lastSegment = acc[acc.length - 1];
      if (lastSegment && lastSegment.color === segment.color) {
        lastSegment.endDegree = segment.endDegree;
      } else {
        acc.push({ ...segment });
      }
      return acc;
    }, [] as typeof segments);

    gradient += mergedSegments
      .map(
        ({ color, startDegree, endDegree }) =>
          `${color} ${startDegree}deg, ${color} ${endDegree}deg`
      )
      .join(", ");

    gradient += ")";

    return { outerGradient: gradient };
  }, [cities, homeTimezone]); // Only recalculate when cities or homeTimezone changes

  // Generate hour markers
  const hourMarkers = useMemo(
    () =>
      Array.from({ length: 24 }).map((_, i) => {
        const angle = i * DEGREES_PER_HOUR - 180;
        const radius = 250;
        const radians = angle * (Math.PI / 180);
        const x = roundCoordinate(Math.cos(radians) * radius);
        const y = roundCoordinate(Math.sin(radians) * radius);

        return (
          <line
            key={i}
            x1="0"
            y1="0"
            x2={x}
            y2={y}
            stroke="rgba(0,0,0,0.2)"
            strokeWidth="1"
            strokeDasharray="4,4"
          />
        );
      }),
    []
  ); // Hour markers never change

  return (
    <div className="w-full flex justify-center my-16 landscape:my-8 px-8">
      <div
        className={`relative ${TW_CLOCK_SIZE}`}
        style={{ touchAction: "none" }}
      >
        <div
          id="clock-container"
          className="absolute inset-0 cursor-grab"
          onMouseDown={eventHandlers.onMouseDown}
          onTouchStart={eventHandlers.onTouchStart}
          style={{
            cursor: isDragging ? "grabbing" : "grab",
            touchAction: "none",
          }}
        >
          {/* Outer Ring */}
          <div
            className="absolute rounded-full"
            style={{
              inset: "0",
              background: outerGradient,
              mixBlendMode: "multiply",
              maskImage: "radial-gradient(transparent 60%, black 60%)",
              WebkitMaskImage: "radial-gradient(transparent 60%, black 60%)",
            }}
          />

          {/* Inner Content Container */}
          <div className="absolute" style={{ inset: "10%" }}>
            {/* Hour Lines */}
            <svg
              className="absolute w-full h-full"
              viewBox="-250 -250 500 500"
            >
              {hourMarkers}
            </svg>

            <NumberLabels inset={-20} />

            {/* City Rings */}
            {Array.from(cities.values()).map((city, index) => (
              <CityRing
                key={city.locationKey}
                city={city}
                index={index}
                homeTimezone={homeTimezone}
                getTimeForCityFunc={getTimeForCityFunc}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConcentricCityClock;

'use client';

import { useClockSettingsStore, useClockStore } from "@store/timeStore";
import { RotateCw } from "lucide-react";

function getOffsetString(baseTime: Date, currentTime: Date): string {
  const diffMs = baseTime.getTime() - currentTime.getTime();
  const diffMinutes = Math.round(diffMs / (1000 * 60));

  if (diffMinutes === 0) return '';

  const hours = Math.floor(Math.abs(diffMinutes) / 60);
  const minutes = Math.abs(diffMinutes) % 60;

  const direction = diffMinutes > 0 ? 'ahead' : 'behind';
  if (hours === 0) {
    return `${minutes}m ${direction}`;
  } else if (minutes === 0) {
    return `${hours}${hours === 1 ? 'hr' : 'hrs'} ${direction}`;
  } else {
    return `${hours}hr ${minutes}m ${direction}`;
  }
}

export default function ResetOffsetButton() {
  const { resetBaseTime } = useClockSettingsStore();
  const { baseTime, currentTime, isAdjusted } = useClockStore();

  const offsetString = isAdjusted ? getOffsetString(baseTime, currentTime) : '';

  if (!isAdjusted) return null;

  return (
    <div className="relative flex items-center">
      <div className="landscape:w-[200px] landscape:grid landscape:grid-cols-2 landscape:items-center landscape:gap-2">
        <div className="flex justify-end">
          <button
            onClick={resetBaseTime}
            className="rounded-full p-1.5 bg-gray-100 hover:bg-gray-200 transition-colors"
            aria-label="Reset to local time"
          >
            <RotateCw className="w-4 h-4 text-gray-600" />
          </button>
        </div>

        {/* Landscape text */}
        <div className="hidden landscape:flex landscape:justify-start landscape:items-center text-sm text-blue-500 whitespace-nowrap">
          {offsetString}
        </div>
      </div>

      {/* Portrait text */}
      <div className="landscape:hidden absolute left-1/2 top-10 -translate-x-1/2 flex flex-col items-center z-50">
        <div className="text-sm text-blue-500 whitespace-nowrap">
          {offsetString}
        </div>
      </div>
    </div>
  );
}
'use client'

import { Button } from "@/components/ui/button"
import { Card, CardContent, CardFooter } from "@/components/ui/card"
import { Separator } from "@/components/ui/separator"
import { useCitiesStore } from "@/store/citiesStore"
import { useClockSettingsStore, useClockStore } from "@/store/timeStore"
import { useMemo } from "react"
import { getTimeCategory, TIME_COLORS } from "@/lib/meetingUtils"
import { CreateMeetingButtonComponent } from './create-meeting-button'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu"
import { Home, MoreVertical, X } from "lucide-react"
import { trackEvent } from "@/lib/analytics"

export function CreateMeetingCard() {
  const { cities, homeCity, removeCity: removeCityFromStore, setHomeCity } = useCitiesStore()
  const { timeFormat } = useClockSettingsStore()
  const { getAdjustedTime } = useClockStore()

  const homeTimezone = homeCity ? cities.get(homeCity)?.timezone : null

  const otherCities = useMemo(() => {
    return Array.from(cities.values()).filter(city => city.locationKey !== homeCity)
  }, [cities, homeCity])

  if (!homeCity || !homeTimezone) return null

  function removeCity(locationKey: string): void {
    const cityName = cities.get(locationKey)?.name || '';
    trackEvent.cityRemoved(cityName);
    removeCityFromStore(locationKey);
  }

  const getBackgroundColorForTime = (date: Date) => {
    const hour = date.getHours()
    const category = getTimeCategory(hour)
    return TIME_COLORS[category]
  }

  const getTimeForCity = (timezone: string) => {
    const adjustedTime = getAdjustedTime()
    return new Date(adjustedTime.toLocaleString('en-US', { timeZone: timezone }))
  }

  const homeTime = homeTimezone ? getTimeForCity(homeTimezone) : null

  return (
    <Card className="w-full max-w-md mx-auto shadow-lg mt-4">
      <CardContent className="p-4">
        {/* Home city */}
        <div className="flex items-center justify-between pb-2">
          <div className="flex items-center gap-3">
            <div
              className="w-10 h-10 rounded-full flex items-center justify-center ring-1 ring-primary/20"
              style={{
                backgroundColor: homeTime ? getBackgroundColorForTime(homeTime) : undefined,
                opacity: 0.8
              }}
            >
              <span className="text-xl" aria-hidden="true">{cities.get(homeCity)?.emoji}</span>
            </div>
            <div>
              <div className="text-2xl font-['SofiaSansRegular'] font-bold tracking-tight tabular-nums">
                {homeTime?.toLocaleTimeString('en-US', timeFormat)}
              </div>
              <div className="text-lg font-['SofiaSansRegular'] text-muted-foreground">
                {cities.get(homeCity)?.name}
              </div>
            </div>
          </div>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="ghost" size="icon" className="h-8 w-8">
                <MoreVertical className="h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              <DropdownMenuItem
                onClick={() => removeCity(homeCity)}
                className="text-red-600 focus:text-red-600 focus:bg-red-50"
              >
                <X className="mr-2 h-4 w-4" />
                Remove city
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>

        <Separator className="my-2" />

        {/* Other cities */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {otherCities.map((city) => {
            const cityTime = getTimeForCity(city.timezone)
            return (
              <div key={city.locationKey} className="flex items-center gap-2 group">
                <div
                  className="w-8 h-8 rounded-full flex items-center justify-center"
                  style={{
                    backgroundColor: getBackgroundColorForTime(cityTime),
                    opacity: 0.8
                  }}
                >
                  <span className="text-lg" aria-hidden="true">{city.emoji}</span>
                </div>
                <div className="min-w-0 flex-1">
                  <div className="flex flex-col sm:flex-row sm:items-center gap-0.5 sm:gap-2">
                    <span className="text-lg font-['SofiaSansRegular'] tabular-nums whitespace-nowrap">
                      {cityTime.toLocaleTimeString('en-US', timeFormat)}
                    </span>
                    <span className="text-base font-['SofiaSansRegular'] text-muted-foreground truncate">
                      {city.name}
                    </span>
                  </div>
                </div>
                <DropdownMenu>
                  <DropdownMenuTrigger asChild>
                    <Button variant="ghost" size="icon" className="h-6 w-6">
                      <MoreVertical className="h-3 w-3" />
                    </Button>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent align="end">
                    <DropdownMenuItem onClick={() => {
                      trackEvent.homeCitySet(city.name);
                      setHomeCity(city.locationKey);
                    }}>
                      <Home className="mr-2 h-4 w-4" />
                      Set as home city
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      onClick={() => removeCity(city.locationKey)}
                      className="text-red-600 focus:text-red-600 focus:bg-red-50"
                    >
                      <X className="mr-2 h-4 w-4" />
                      Remove city
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            )
          })}
        </div>
      </CardContent>
      <CardFooter className="p-4 pt-2">
        <CreateMeetingButtonComponent />
      </CardFooter>
    </Card>
  )
}
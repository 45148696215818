'use client';

import React, { useEffect, useState } from 'react';
import { Portal } from '@radix-ui/react-portal';
import { City } from '@store/citiesStore';
import { useClockSettingsStore } from '@store/timeStore';
import { getTimeCategory, TIME_COLORS } from '@lib/meetingUtils';

interface ClockCityProps {
  cities: City[];
  angle: number;
  baseTime: Date;
  homeCity: string | null;
  radius: number;
  hideLabel?: boolean;
}

interface Position {
  left: number;
  top: number;
}

const EMOJI_CLASSES = 'flex items-center space-x-0.5';

function roundPosition(value: number): string {
  return value.toFixed(3);
}

function deduplicateEmojis(cities: City[]): Array<{ emoji: string, locationKey: string }> {
  const uniqueEmojisMap = new Map<string, { emoji: string, locationKey: string }>();

  cities.forEach(city => {
    if (city.emoji && city.locationKey) {
      if (!uniqueEmojisMap.has(city.emoji)) {
        uniqueEmojisMap.set(city.emoji, { emoji: city.emoji, locationKey: city.locationKey });
      }
    }
  });

  return Array.from(uniqueEmojisMap.values());
}

function getTranslations(angle: number) {
  const radians = (angle - 90) * (Math.PI / 180);
  const translateX = -75 * Math.cos(radians);
  const translateY = -75 * Math.sin(radians);
  return { translateX, translateY };
}

function getTimeForCity(timezone: string, baseTime: Date): Date {
  return new Date(baseTime.toLocaleString('en-US', { timeZone: timezone }));
}

function roundAngle(angle: number): number {
  return Number(angle.toFixed(2));
}

export function CityClock({ cities, angle, baseTime, homeCity, radius, hideLabel = false }: ClockCityProps) {
  // Validate that all cities have required properties
  const invalidCities = cities.filter(city => !city.locationKey || !city.emoji);
  if (invalidCities.length > 0) {
    console.error('Found invalid cities:', invalidCities);
  }

  const roundedAngle = roundAngle(angle);
  const radians = (roundedAngle + 90) * (Math.PI / 180);
  const dotX = 50 + radius * Math.cos(radians);
  const dotY = 50 + radius * Math.sin(radians);

  const [isMounted, setIsMounted] = useState(false);

  const emojis = deduplicateEmojis(cities);

  // Validate emoji array
  const invalidEmojis = emojis.filter(e => !e.locationKey);
  if (invalidEmojis.length > 0) {
    console.error('Found emojis without locationKeys:', invalidEmojis);
  }

  const isHome = cities.some(city => city.locationKey === homeCity);

  const timeFormat = useClockSettingsStore(state => state.timeFormat);

  // Get the time category for the first city (they should all be in the same hour)
  const cityTime = getTimeForCity(cities[0].timezone, baseTime);
  const timeCategory = getTimeCategory(cityTime.getHours());
  const backgroundColor = TIME_COLORS[timeCategory];

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const translations = getTranslations(roundedAngle);

  // Group cities by time
  const groupedByTime = cities.reduce((groups, city) => {
    const cityTime = getTimeForCity(city.timezone, baseTime);
    const timeString = cityTime.toLocaleTimeString('en-US', timeFormat);

    if (!groups[timeString]) {
      groups[timeString] = {
        time: timeString,
        cities: []
      };
    }

    groups[timeString].cities.push({
      name: city.abbreviation ?? city.name,
      isHome: city.locationKey === homeCity,
      locationKey: city.locationKey
    });

    return groups;
  }, {} as Record<string, { time: string, cities: Array<{ name: string, isHome: boolean, locationKey: string }> }>);

  return (
    <>
      {/* Emoji Pill */}
      <div
        className={`${EMOJI_CLASSES} rounded-full shadow px-1.5 py-0.5 ${isHome ? 'ring-2 ring-blue-500' : ''} z-30`}
        style={{
          position: 'absolute',
          left: `${roundPosition(dotX)}%`,
          top: `${roundPosition(dotY)}%`,
          transform: `translate(-50%, -50%) rotate(${roundedAngle}deg)`,
          backgroundColor,
          opacity: 0.9,
          zIndex: 30,
        }}
      >
        {[...emojis].reverse().map(({ emoji, locationKey },) => {
          return (
            <span
              key={locationKey}
              className="text-lg sm:text-xl md:text-2xl lg:text-3xl"
              style={{
                transform: `rotate(${-roundedAngle}deg)`,
                lineHeight: 1,
              }}
            >
              {emoji}
            </span>
          );
        })}
      </div>

      {/* Text */}
      {!hideLabel && isMounted && (
        <div
          className="absolute text-xs sm:text-sm md:text-base lg:text-lg max-w-full break-words z-50"
          style={{
            left: `${roundPosition(dotX)}%`,
            top: `${roundPosition(dotY)}%`,
            transform: `translate(-50%, -50%) translate(${translations.translateX}%, ${translations.translateY}%)`,
          }}
        >
          <div className="rounded-md bg-popover/75 shadow-md backdrop-blur-[2px]">
            <div className="text-center px-1.5 py-1">
              {Object.values(groupedByTime).reverse().map((group,) => (
                <div key={`time-${group.time}`}>
                  {group.cities.map((city, index) => (
                    <div
                      key={`city-${city.locationKey}`}
                      className={`text-base sm:text-sm md:text-base lg:text-lg font-['SofiaSansCity'] font-normal leading-tight 
                          ${city.isHome ? 'text-blue-500 font-medium' : 'text-gray-500'}
                          ${index !== group.cities.length - 1 ? 'mb-0.5' : ''}`}
                    >
                      {city.name}
                    </div>
                  ))}
                  <div
                    className={`text-xl sm:text-base md:text-lg lg:text-xl font-['SofiaSansTime'] whitespace-nowrap leading-none 
                        ${group.cities.some(c => c.isHome) ? 'text-blue-700' : 'text-gray-900'}`}
                  >
                    {group.time}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
'use client';

import React from 'react';
import { useDragTime } from '@hooks/useDragTime';

interface ClockRingProps {
  children: React.ReactNode;
}

export function ClockRing({ children }: ClockRingProps) {
  const { isDragging, eventHandlers } = useDragTime();

  return (
    <div
      className="absolute inset-[0] cursor-grab clock-ring-wrapper"
      onMouseDown={eventHandlers.onMouseDown}
      onTouchStart={eventHandlers.onTouchStart}
      style={{ cursor: isDragging ? 'grabbing' : 'grab', touchAction: 'none' }}
    >
      {children}
    </div>
  );
} 
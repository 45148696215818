import { useClockSettingsStore } from "@store/timeStore";
import React from "react";

export function ClockBackground() {
  return (
    <>
      <div
        className="mx-auto my-auto rounded-full absolute inset-[0%] bg-white"
      />
      <div
        id="clock-gradient"
        className="mx-auto my-auto rounded-full absolute inset-[8%]"
        style={{
          background: `conic-gradient(
            from -90deg at 51.12% 48.95%, 
            #E7606F 0deg, 
            #E67665 27.024672031402588deg, 
            #F3D162 53.79655659198761deg, 
            #F4DA62 99.48371171951294deg, 
            #FCFBD0 135.56139707565308deg, 
            #8CB2DE 162.35448718070984deg, 
            #45458D 197.20023393630981deg, 
            #393371 219.9525761604309deg, 
            #372F46 275.8536100387573deg, 
            #2D2422 360deg
          )`,
        }}
      />
      <Crosshairs />
      <NumberLabels inset={1} />
    </>
  );
}

function Crosshairs() {
  return <>
    <div id="clock-crosshairs" className="absolute inset-0 flex items-center justify-center pointer-events-none">
      <div className="w-full h-[2px] mb-[2px] bg-white opacity-90" />
    </div>
    <div id="clock-crosshairs" className="absolute inset-0 flex items-center justify-center pointer-events-none">
      <div className="h-full w-[2px] ml-[2px] bg-white opacity-90" />
    </div>
  </>
}

export function NumberLabels({ inset }: { inset: number }) {
  const { timeFormat } = useClockSettingsStore();

  const labels12h = ["12p", "6p", "12a", "6a"];
  const labels24h = ["12", "18", "00", "06"];
  const labels = timeFormat.hour12 ? labels12h : labels24h;
  return (
    <div
      id="clock-number-labels"
      className="absolute flex items-center justify-center pointer-events-none text-muted-foreground"
      style={{ inset: `${inset}%` }}
    >
      <span className="absolute top-0 mt-[-8px] font-['SofiaSansNumbers'] text-2xl">{labels[0]}</span>
      <span className="absolute right-0 font-['SofiaSansNumbers'] text-2xl">{labels[1]}</span>
      <span className="absolute bottom-0 mb-[-8px] font-['SofiaSansNumbers'] text-2xl">{labels[2]}</span>
      <span className="absolute left-0 font-['SofiaSansNumbers'] text-2xl">{labels[3]}</span>
    </div>
  );
}

import { CalendarProvider } from '@lib/calendars';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface TimeState {
  currentTime: Date;
  baseTime: Date;
  isAdjusted: boolean;
  globalIsDragging: boolean;
  setCurrentTime: (time: Date) => void;
  setBaseTime: (time: Date) => void;
  getAdjustedTime: () => Date;
  setIsAdjusted: (isAdjusted: boolean) => void;
  setGlobalIsDragging: (isDragging: boolean) => void;
}

export type ClockMode = "clock" | "schedule";

type TimeFormat = {
  hour: "2-digit" | "numeric";
  minute: "2-digit" | "numeric";
  hour12: boolean;
}

function roundToNearest15Min(date: Date): Date {
  const ms = 1000 * 60 * 15;
  return new Date(Math.round(date.getTime() / ms) * ms);
}

export const useClockStore = create<TimeState>()((set, get) => ({
  currentTime: new Date(),
  baseTime: new Date(),
  isAdjusted: false,
  globalIsDragging: false,
  setCurrentTime: (time: Date) => set({ currentTime: time }),
  setBaseTime: (time: Date) => set({ baseTime: time, isAdjusted: true }),
  setIsAdjusted: (isAdjusted: boolean) => set({ isAdjusted }),
  getAdjustedTime: () => get().isAdjusted ? get().baseTime : get().currentTime,
  setGlobalIsDragging: (isDragging: boolean) => set({ globalIsDragging: isDragging }),
}));

const initialSettings: {
  timeFormat: TimeFormat;
  clockMode: ClockMode;
  globalIsDragging: boolean;
  preferredMeetingType: CalendarProvider;
  isHydrated: boolean;
} = {
  timeFormat: { hour: "2-digit", minute: "2-digit", hour12: true },
  clockMode: "clock",
  globalIsDragging: false,
  preferredMeetingType: 'google',
  isHydrated: false,
};

export const useClockSettingsStore = create<{
  timeFormat: TimeFormat;
  clockMode: ClockMode;
  globalIsDragging: boolean;
  preferredMeetingType: CalendarProvider;
  isHydrated: boolean;
  setClockMode: (mode: ClockMode) => void;
  setTimeFormat: (format: TimeFormat) => void;
  resetBaseTime: () => void;
  updateBaseTime: (deltaMs: number) => void;
  snapToNearest15: () => void;
  startTimeAdjustment: () => void;
  setGlobalIsDragging: (isDragging: boolean) => void;
  setPreferredMeetingType: (type: CalendarProvider) => void;
}>()(
  persist(
    (set) => ({
      ...initialSettings,

      setClockMode: (mode: ClockMode) => set((state) => ({
        ...state,
        clockMode: mode
      })),

      setTimeFormat: (format: TimeFormat) => set((state) => ({
        ...state,
        timeFormat: format
      })),

      resetBaseTime: () => {
        const clockStore = useClockStore.getState();
        clockStore.setBaseTime(clockStore.currentTime);
        clockStore.setIsAdjusted(false);
      },

      startTimeAdjustment: () => {
        const currentTime = useClockStore.getState().currentTime;
        useClockStore.getState().setBaseTime(roundToNearest15Min(currentTime));
      },

      updateBaseTime: (deltaMs: number) => {
        const state = useClockStore.getState();
        state.setBaseTime(new Date(state.baseTime.getTime() + deltaMs));
      },

      snapToNearest15: () => {
        const state = useClockStore.getState();
        state.setBaseTime(roundToNearest15Min(state.baseTime));
      },

      setGlobalIsDragging: (isDragging: boolean) => set((state) => ({
        ...state,
        globalIsDragging: isDragging
      })),

      setPreferredMeetingType: (type: CalendarProvider) => set((state) => ({
        ...state,
        preferredMeetingType: type
      })),
    }),
    {
      name: 'clock-settings',
      onRehydrateStorage: () => (state) => {
        if (state) {
          state.isHydrated = true;
        }
      },
    }
  )
);

// Initialize time updates on client only
if (typeof window !== 'undefined') {
  useClockStore.getState().setCurrentTime(new Date());
  setInterval(() => {
    useClockStore.getState().setCurrentTime(new Date());
  }, 1000);
}
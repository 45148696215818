'use client'

import React, { useState } from 'react'
import { Button } from "@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu"
import { ChevronDown, FileDown } from 'lucide-react'
import Image from 'next/image'
import { useClockSettingsStore, useClockStore } from '@store/timeStore'
import { useCitiesStore } from '@store/citiesStore'
import { CalendarProvider, createCalendarEvent } from '@lib/calendars'
import { trackEvent } from '@/lib/analytics';




interface MeetingOption {
  type: CalendarProvider
  label: string
  icon: React.ReactNode
  action: () => void
}

const GoogleIcon = () => (
  <Image
    src="/icons/google-calendar.svg"
    alt="Google Calendar"
    width={16}
    height={16}
    className="mr-2"
  />
)

const OutlookIcon = () => (
  <Image
    src="/icons/outlook-calendar.svg"
    alt="Outlook Calendar"
    width={16}
    height={16}
    className="mr-2"
  />
)

const AppleIcon = () => (
  <Image
    src="/icons/apple-calendar.svg"
    alt="Apple Calendar"
    width={16}
    height={16}
    className="mr-2"
  />
)

// const YahooIcon = () => (
//   <Image
//     src="/icons/yahoo-calendar.svg"
//     alt="Yahoo Calendar"
//     width={16}
//     height={16}
//     className="mr-2"
//   />
// )

export function CreateMeetingButtonComponent() {
  const { preferredMeetingType, setPreferredMeetingType } = useClockSettingsStore()
  const [selectedMeeting, setSelectedMeeting] = useState<CalendarProvider>(preferredMeetingType)
  const { homeCity, cities } = useCitiesStore()
  const { getAdjustedTime } = useClockStore()

  const meetingOptions: MeetingOption[] = [
    {
      type: 'google',
      label: 'Google Calendar',
      icon: <GoogleIcon />,
      action: () => createMeeting('google')
    },
    {
      type: 'outlook',
      label: 'Outlook Calendar',
      icon: <OutlookIcon />,
      action: () => createMeeting('outlook')
    },
    // {
    //   type: 'yahoo',
    //   label: 'Yahoo Calendar',
    //   icon: <YahooIcon />,
    //   action: () => createMeeting('yahoo')
    // },
    {
      type: 'apple',
      label: 'Apple Calendar',
      icon: <AppleIcon />,
      action: () => createMeeting('apple')
    },
    {
      type: 'ics',
      label: 'Download ICS File',
      icon: <FileDown className="mr-2 h-4 w-4" />,
      action: () => createMeeting('ics')
    }
  ]

  const selectedOption = meetingOptions.find(option => option.type === selectedMeeting)

  const handleCreateMeeting = () => {
    selectedOption?.action()
  }

  const createMeeting = (provider: CalendarProvider) => {
    if (!homeCity) {
      console.error('Home city is not set.')
      return
    }
    trackEvent.meetingCreated(provider);
    const adjustedTime = getAdjustedTime()
    const startTime = adjustedTime
    const endTime = new Date(adjustedTime.getTime() + 60 * 60 * 1000) // 1 hour meeting

    const eventBody = Array.from(cities.values()).map(city => {
      const cityTime = new Date(adjustedTime.toLocaleString('en-US', { timeZone: city.timezone }))
      return `${city.emoji} ${city.name}: ${cityTime.toLocaleTimeString()}`
    }).join('\n')

    const timeDetails = {
      title: 'Meeting',
      description: `${eventBody}\n\nPowered by timeywimey.co`,
      startTime,
      endTime
    }

    createCalendarEvent(provider, timeDetails)
  }

  return (
    <div className="flex w-full">
      <Button
        onClick={handleCreateMeeting}
        className="flex-grow bg-black text-white hover:bg-gray-800 rounded-r-none justify-center items-center"
        size="lg"
      >
        {selectedOption?.icon}
        Create meeting
      </Button>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            className="bg-black text-white hover:bg-gray-800 px-2 rounded-l-none border-l border-gray-700"
            size="lg"
            aria-label="More meeting options"
          >
            <ChevronDown className="h-4 w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-[200px]">
          {meetingOptions.map((option) => (
            <DropdownMenuItem
              key={option.type}
              onClick={() => {
                setSelectedMeeting(option.type)
                setPreferredMeetingType(option.type)
                option.action()
              }}
            >
              {option.icon}
              {option.label}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
'use client'
import { useClockSettingsStore } from '@store/timeStore';
import { trackEvent } from '@/lib/analytics';

export default function TimeFormatToggle() {
  const { timeFormat, setTimeFormat } = useClockSettingsStore();

  const handleToggle = () => {
    const newFormat = !timeFormat.hour12 ? '24h' : '12h';
    trackEvent.timeFormatChanged(newFormat);
    setTimeFormat({ ...timeFormat, hour12: !timeFormat.hour12 });
  };

  return (
    <div className="inline-flex items-center bg-gray-200 rounded-full p-1" style={{ width: '95.5px', padding: '0.25rem' }}>
      <button
        onClick={handleToggle}
        className="relative flex items-center w-full"
        style={{ height: '40px' }}
        aria-label="Toggle between 12 and 24 hour format"
      >
        <div
          className="absolute flex items-center justify-center bg-white"
          style={{
            borderRadius: '38px',
            boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.15)',
            width: '35.5px',
            height: '35.5px',
            left: '2.5px',
            transform: !timeFormat.hour12 ? 'translateX(47.5px)' : 'translateX(0)',
            transition: 'transform 0.5s cubic-bezier(0.4, 0, 0.2, 1)'
          }}
        />
        <div className="relative flex items-center justify-between w-full" style={{ paddingLeft: '10px', paddingRight: '8px' }}>
          <span
            className="flex items-center justify-center w-6 text-sm font-medium"
            style={{ color: timeFormat.hour12 ? '#0F172A' : '#777777' }}
          >
            12h
          </span>
          <span
            className="flex items-center justify-center w-6 text-sm font-medium"
            style={{ color: !timeFormat.hour12 ? '#0F172A' : '#777777' }}
          >
            24h
          </span>
        </div>
      </button>
    </div>
  );
}
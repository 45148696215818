'use client'
import { CirclePlus } from 'lucide-react';
import CityAutocomplete from "./CityAutocomplete";
import { useRef } from 'react';
import { useCitiesStore } from '@store/citiesStore';
import VoiceInput from './VoiceInput';

export default function CitySearch() {
  const autocompleteRef = useRef<HTMLInputElement>(null);
  const { addCityWithTimezoneData, removeCity, setHomeCity, cities } = useCitiesStore();

  return (
    <div className="relative group focus-within:text-blue-500 w-full max-w-md mx-auto">
      <CityAutocomplete
        ref={autocompleteRef}
        className="h-12 px-4 py-2 pl-10 pr-12 rounded-full text-white bg-blue-500 focus:bg-white focus:text-black focus:ring-2 focus:ring-blue-500 transition-colors duration-200 placeholder:text-white font-['SofiaSansRegular'] text-[16px]"
        placeholder="Add City"
      />
      <CirclePlus
        className="absolute left-3 top-1/2 -translate-y-1/2 text-white group-focus-within:text-blue-500 pointer-events-none transition-colors duration-200"
        size={20}
      />
      <VoiceInput
        cities={cities}
        onAddCity={addCityWithTimezoneData}
        onRemoveCity={removeCity}
        onSetHomeCity={setHomeCity}
      />
    </div>
  );
} 
